@import url(https://fonts.googleapis.com/css2?family=Work+Sans&display=swap);
/* BASICS */

.CodeMirror {
  /* Set height, width, borders, and global font properties here */
  font-family: monospace;
  min-height: 100%;
  color: black;
  direction: ltr;
  font-size: 1.25rem;
  height: 100%;
}

/* PADDING */

.CodeMirror-lines {
  padding: 4px 0; /* Vertical padding around content */
}
.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  padding: 0 4px; /* Horizontal padding of content */
}

.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
  background-color: white; /* The little square between H and V scrollbars */
}

/* GUTTER */

.CodeMirror-gutters {
  border-right: 1px solid #ddd;
  background-color: #f7f7f7;
  white-space: nowrap;
}
.CodeMirror-linenumbers {
}
.CodeMirror-linenumber {
  padding: 0 3px 0 5px;
  min-width: 20px;
  text-align: right;
  color: #999;
  white-space: nowrap;
}

.CodeMirror-guttermarker {
  color: black;
}
.CodeMirror-guttermarker-subtle {
  color: #999;
}

/* CURSOR */

.CodeMirror-cursor {
  border-left: 1px solid black;
  border-right: none;
  width: 0;
}
/* Shown when moving in bi-directional text */
.CodeMirror div.CodeMirror-secondarycursor {
  border-left: 1px solid silver;
}
.cm-fat-cursor .CodeMirror-cursor {
  width: auto;
  border: 0 !important;
  background: #7e7;
}
.cm-fat-cursor div.CodeMirror-cursors {
  z-index: 1;
}
.cm-fat-cursor-mark {
  background-color: rgba(20, 255, 20, 0.5);
  animation: blink 1.06s steps(1) infinite;
}
.cm-animate-fat-cursor {
  width: auto;
  border: 0;
  animation: blink 1.06s steps(1) infinite;
  background-color: #7e7;
}
@keyframes blink {
  0% {
  }
  50% {
    background-color: transparent;
  }
  100% {
  }
}

/* Can style cursor different in overwrite (non-insert) mode */
.CodeMirror-overwrite .CodeMirror-cursor {
}

.cm-tab {
  display: inline-block;
  text-decoration: inherit;
}

.CodeMirror-rulers {
  position: absolute;
  left: 0;
  right: 0;
  top: -50px;
  bottom: 0;
  overflow: hidden;
}
.CodeMirror-ruler {
  border-left: 1px solid #ccc;
  top: 0;
  bottom: 0;
  position: absolute;
}

/* DEFAULT THEME */

.cm-s-default .cm-header {
  color: blue;
}
.cm-s-default .cm-quote {
  color: #090;
}
.cm-negative {
  color: #d44;
}
.cm-positive {
  color: #292;
}
.cm-header,
.cm-strong {
  font-weight: bold;
}
.cm-em {
  font-style: italic;
}
.cm-link {
  text-decoration: underline;
}
.cm-strikethrough {
  text-decoration: line-through;
}

.cm-s-default .cm-keyword {
  color: #708;
}
.cm-s-default .cm-atom {
  color: #219;
}
.cm-s-default .cm-number {
  color: #164;
}
.cm-s-default .cm-def {
  color: #00f;
}
.cm-s-default .cm-variable,
.cm-s-default .cm-punctuation,
.cm-s-default .cm-property,
.cm-s-default .cm-operator {
}
.cm-s-default .cm-variable-2 {
  color: #05a;
}
.cm-s-default .cm-variable-3,
.cm-s-default .cm-type {
  color: #085;
}
.cm-s-default .cm-comment {
  color: #a50;
}
.cm-s-default .cm-string {
  color: #a11;
}
.cm-s-default .cm-string-2 {
  color: #f50;
}
.cm-s-default .cm-meta {
  color: #555;
}
.cm-s-default .cm-qualifier {
  color: #555;
}
.cm-s-default .cm-builtin {
  color: #30a;
}
.cm-s-default .cm-bracket {
  color: #997;
}
.cm-s-default .cm-tag {
  color: #170;
}
.cm-s-default .cm-attribute {
  color: #00c;
}
.cm-s-default .cm-hr {
  color: #999;
}
.cm-s-default .cm-link {
  color: #00c;
}

.cm-s-default .cm-error {
  color: #f00;
}
.cm-invalidchar {
  color: #f00;
}

.CodeMirror-composing {
  border-bottom: 2px solid;
}

/* Default styles for common addons */

div.CodeMirror span.CodeMirror-matchingbracket {
  color: #0b0;
}
div.CodeMirror span.CodeMirror-nonmatchingbracket {
  color: #a22;
}
.CodeMirror-matchingtag {
  background: rgba(255, 150, 0, 0.3);
}
.CodeMirror-activeline-background {
  background: #e8f2ff;
}

/* STOP */

/* The rest of this file contains styles related to the mechanics of
   the editor. You probably shouldn't touch them. */

.CodeMirror {
  position: relative;
  overflow: hidden;
  background: white;
}

.CodeMirror-scroll {
  overflow: scroll !important; /* Things will break if this is overridden */
  /* 50px is the magic margin used to hide the element's real scrollbars */
  /* See overflow: hidden in .CodeMirror */
  margin-bottom: -50px;
  margin-right: -50px;
  padding-bottom: 50px;
  height: 100%;
  outline: none; /* Prevent dragging from highlighting the element */
  position: relative;
}
.CodeMirror-sizer {
  position: relative;
  border-right: 50px solid transparent;
}

/* The fake, visible scrollbars. Used to force redraw during scrolling
   before actual scrolling happens, thus preventing shaking and
   flickering artifacts. */
.CodeMirror-vscrollbar,
.CodeMirror-hscrollbar,
.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
  position: absolute;
  z-index: 6;
  display: none;
  outline: none;
}
.CodeMirror-vscrollbar {
  right: 0;
  top: 0;
  overflow-x: hidden;
  overflow-y: scroll;
}
.CodeMirror-hscrollbar {
  bottom: 0;
  left: 0;
  overflow-y: hidden;
  overflow-x: scroll;
}
.CodeMirror-scrollbar-filler {
  right: 0;
  bottom: 0;
}
.CodeMirror-gutter-filler {
  left: 0;
  bottom: 0;
}

.CodeMirror-gutters {
  position: absolute;
  left: 0;
  top: 0;
  min-height: 100%;
  z-index: 3;
}
.CodeMirror-gutter {
  white-space: normal;
  height: 100%;
  display: inline-block;
  vertical-align: top;
  margin-bottom: -50px;
}
.CodeMirror-gutter-wrapper {
  position: absolute;
  z-index: 4;
  background: none !important;
  border: none !important;
}
.CodeMirror-gutter-background {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 4;
}
.CodeMirror-gutter-elt {
  position: absolute;
  cursor: default;
  z-index: 4;
}
.CodeMirror-gutter-wrapper ::selection {
  background-color: transparent;
}
.CodeMirror-gutter-wrapper ::-moz-selection {
  background-color: transparent;
}

.CodeMirror-lines {
  cursor: text;
  min-height: 1px; /* prevents collapsing before first draw */
}
.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
  /* Reset some styles that the rest of the page might have set */
  border-radius: 0;
  border-width: 0;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  margin: 0;
  white-space: pre;
  word-wrap: normal;
  line-height: inherit;
  color: inherit;
  z-index: 2;
  position: relative;
  overflow: visible;
  -webkit-tap-highlight-color: transparent;
  -webkit-font-variant-ligatures: contextual;
  font-feature-settings: "calt";
  font-variant-ligatures: contextual;
}
.CodeMirror-wrap pre.CodeMirror-line,
.CodeMirror-wrap pre.CodeMirror-line-like {
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: normal;
}

.CodeMirror-linebackground {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
}

.CodeMirror-linewidget {
  position: relative;
  z-index: 2;
  padding: 0.1px; /* Force widget margins to stay inside of the container */
}

.CodeMirror-widget {
}

.CodeMirror-rtl pre {
  direction: rtl;
}

.CodeMirror-code {
  outline: none;
}

/* Force content-box sizing for the elements where we expect it */
.CodeMirror-scroll,
.CodeMirror-sizer,
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber {
  box-sizing: content-box;
}

.CodeMirror-measure {
  position: absolute;
  width: 100%;
  height: 0;
  overflow: hidden;
  visibility: hidden;
}

.CodeMirror-cursor {
  position: absolute;
  pointer-events: none;
}
.CodeMirror-measure pre {
  position: static;
}

div.CodeMirror-cursors {
  visibility: hidden;
  position: relative;
  z-index: 3;
}
div.CodeMirror-dragcursors {
  visibility: visible;
}

.CodeMirror-focused div.CodeMirror-cursors {
  visibility: visible;
}

.CodeMirror-selected {
  background: #d9d9d9;
}
.CodeMirror-focused .CodeMirror-selected {
  background: #d7d4f0;
}
.CodeMirror-crosshair {
  cursor: crosshair;
}
.CodeMirror-line::selection,
.CodeMirror-line > span::selection,
.CodeMirror-line > span > span::selection {
  background: #d7d4f0;
}
.CodeMirror-line::-moz-selection,
.CodeMirror-line > span::-moz-selection,
.CodeMirror-line > span > span::-moz-selection {
  background: #d7d4f0;
}

.cm-searching {
  background-color: #ffa;
  background-color: rgba(255, 255, 0, 0.4);
}

/* Used to force a border model for a node */
.cm-force-border {
  padding-right: 0.1px;
}

@media print {
  /* Hide the cursor when printing */
  .CodeMirror div.CodeMirror-cursors {
    visibility: hidden;
  }
}

/* See issue #2901 */
.cm-tab-wrap-hack:after {
  content: '';
}

/* Help users use markselection to safely style text background */
span.CodeMirror-selectedtext {
  background: none;
}

.cm-s-material.CodeMirror {
  background-color: #263238;
  color: #eeffff;
}

.cm-s-material .CodeMirror-gutters {
  background: #263238;
  color: #546e7a;
  border: none;
}

.cm-s-material .CodeMirror-guttermarker,
.cm-s-material .CodeMirror-guttermarker-subtle,
.cm-s-material .CodeMirror-linenumber {
  color: #546e7a;
}

.cm-s-material .CodeMirror-cursor {
  border-left: 1px solid #ffcc00;
}

.cm-s-material div.CodeMirror-selected {
  background: rgba(128, 203, 196, 0.2);
}

.cm-s-material.CodeMirror-focused div.CodeMirror-selected {
  background: rgba(128, 203, 196, 0.2);
}

.cm-s-material .CodeMirror-line::selection,
.cm-s-material .CodeMirror-line > span::selection,
.cm-s-material .CodeMirror-line > span > span::selection {
  background: rgba(128, 203, 196, 0.2);
}

.cm-s-material .CodeMirror-line::-moz-selection,
.cm-s-material .CodeMirror-line > span::-moz-selection,
.cm-s-material .CodeMirror-line > span > span::-moz-selection {
  background: rgba(128, 203, 196, 0.2);
}

.cm-s-material .CodeMirror-activeline-background {
  background: rgba(0, 0, 0, 0.5);
}

.cm-s-material .cm-keyword {
  color: #c792ea;
}

.cm-s-material .cm-operator {
  color: #89ddff;
}

.cm-s-material .cm-variable-2 {
  color: #eeffff;
}

.cm-s-material .cm-variable-3,
.cm-s-material .cm-type {
  color: #f07178;
}

.cm-s-material .cm-builtin {
  color: #ffcb6b;
}

.cm-s-material .cm-atom {
  color: #f78c6c;
}

.cm-s-material .cm-number {
  color: #ff5370;
}

.cm-s-material .cm-def {
  color: #82aaff;
}

.cm-s-material .cm-string {
  color: #c3e88d;
}

.cm-s-material .cm-string-2 {
  color: #f07178;
}

.cm-s-material .cm-comment {
  color: #546e7a;
}

.cm-s-material .cm-variable {
  color: #f07178;
}

.cm-s-material .cm-tag {
  color: #ff5370;
}

.cm-s-material .cm-meta {
  color: #ffcb6b;
}

.cm-s-material .cm-attribute {
  color: #c792ea;
}

.cm-s-material .cm-property {
  color: #c792ea;
}

.cm-s-material .cm-qualifier {
  color: #decb6b;
}

.cm-s-material .cm-variable-3,
.cm-s-material .cm-type {
  color: #decb6b;
}

.cm-s-material .cm-error {
  color: rgba(255, 255, 255, 1);
  background-color: #ff5370;
}

.cm-s-material .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}

.CodeMirror-hints {
  position: absolute;
  z-index: 10;
  overflow: hidden;
  list-style: none;

  margin: 0;
  padding: 2px;
  box-shadow: 2px 3px 5px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  border: 1px solid white;

  background: #555;
  font-size: 1rem;
  font-family: monospace;

  max-height: 30em;
  overflow-y: auto;
}

.CodeMirror-hint {
  margin: 0;
  padding: 0 4px;
  border-radius: 2px;
  white-space: pre;
  color: #fff;
  cursor: pointer;
}

li.CodeMirror-hint-active {
  background: #08f;
  color: white;
}

:root {
  font-family: "Work Sans", sans-serif;
  --dark-navy: #0A1F44; /* Dark navy color assumed from the screenshot */
  --light-text: #FFFFFF; /* White text color for better contrast */
  --accent-red: #E83A30; /* Red accent color assumed from the screenshot */
  --modal-bg: #222222; /* Modal background color, a slightly lighter shade */
  --button-hover: #D32F2F; /* Button hover color, a darker shade of red */
  --border-color: #444444; /* Border color for elements like modals */
  --input-bg: #333333; /* Input field background color */
  --input-border: #666666; /* Input field border color */
  --notification-bg: #FFA07A; /* Notification background color */
  --button-bg: #89ddff; /* Default button background color */
  --button-text: #0A1F44; /* Button text color to match the dark navy theme */
}

a {
  text-decoration: none;
  color: var(--light-text);
}

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  background-color: var(--dark-navy);
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0.5rem;
  padding: 0rem;
  display: block;
  color: var(--light-text);
}

h3 {
  margin-right: 0;
}

td,
th {
  height: 1rem;
}

ul {
  padding: 0 0.5rem;
}

li {
  list-style-type: none;
}

button {
  border: none;
  background-color: var(--button-bg);
  color: var(--button-text);
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: var(--button-hover);
}

input:disabled {
  background-color: var(--input-bg);
  border-color: var(--input-border);
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-links a {
  color: var(--light-text);
  margin: 0 0.5rem;
  padding: 0.25rem;
  display: flex;
  align-items: center;
}

.nav-links a:hover {
  color: var(--accent-red);
}

.nav-links a span {
  margin: 0 0.25rem;
}

.container {
  display: grid;
  padding: 3rem 0 0 0;
  height: 100vh;
  grid-template-columns: 250px minmax(0, 4fr) minmax(0, 2fr);
  grid-template-areas: "menu main result";
}

.menu {
  grid-area: menu;
  background-color: var(--modal-bg);
  padding: 0.5rem;
  box-shadow: 2px -2px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.result {
  grid-area: result;
  overflow: hidden;
  padding: 0.5rem 0.25rem 1rem 0.25rem;
}

.main {
  grid-area: main;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding: 0.5rem 0.25rem 1rem 0.25rem;
}

.samples-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0.5rem 0.5rem 0 0.5rem;
  overflow: hidden;
}

.samples-wrapper > * {
  position: relative;
  overflow: hidden;
}

.output-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.5rem;
}

.counter {
  justify-self: flex-end;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counter.all-match {
  color: #93eea8;
}

.counter.no-match {
  color: #f78c6c;
}

/* MODAL */

.modal {
  background-color: rgba(255, 255, 255, 0.3);
  position: fixed;
  z-index: 3;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 5vh;
  top: 0;
  left: 0;
}

.modal-container {
  background-color: var(--modal-bg);
  padding: 1rem;
  border: 1px solid var(--border-color);
  box-shadow: 3px 3px rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 50vw;
}

.modal-header {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.modal-header h3 {
  margin: 0 0 0 0.5rem;
  color: var(--light-text);
}

.modal-header svg {
  color: var(--light-text);
}

.modal-header svg:hover {
  color: var(--accent-red);
  cursor: pointer;
}

.grid-body {
  max-height: 75vh;
  overflow-y: auto;
}

.modal-content input {
  border-radius: 5px;
  font-size: 1rem;
  padding: 0.5rem;
  width: 100%;
  background-color: var(--input-bg);
  border: 1px solid var(--input-border);
  color: var(--light-text);
}

.modal-footer {
  margin-top: 1rem;
}

.notification {
  display: flex;
  align-items: center;
  background-color: var(--notification-bg);
  box-shadow: 2px 2px rgba(0, 0, 0, 0.6);
  width: 100%;
  padding: 0.5rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  color: var(--dark-navy);
}

.notification span {
  margin-left: 0.5rem;
}

.error {
  background-color: #e57373; /* Adjusted for a consistent theme */
  color: var(--light-text);
}

.warn {
  background-color: #fff176; /* Adjusted for a consistent theme */
  color: var(--dark-navy);
}

.row div {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  padding-top: 0.5rem;
  margin: 0 0.5rem;
}

.grid-headers div {
  display: flex;
  align-items: center;
}

.pattern-grid {
  background-color: #333;
}

.pattern-grid .grid-headers {
  font-weight: bold;
  color: #fff;
  background-color: #555;
  display: grid;
  grid-template-columns: 2fr 6fr 3rem 3rem;
  padding: 0.5rem;
  border: 1px solid #444;
  height: 3rem;
  width: 100%;
}

.pattern-grid .row {
  display: grid;
  padding: 0.5rem;
  grid-template-columns: 2fr 6fr 3rem 3rem;
  border: 1px solid #666;
  border-top: none;
}

.btn.delete {
  background-color: #f44336; /* Changed from salmon to a consistent red */
}

.btn.edit {
  background-color: var(--accent-red); /* Use the accent color for edit buttons */
}

.btn.success {
  background-color: #4caf50; /* A success green color */
}

.btn.secondary {
  background-color: transparent;
  color: var(--light-text);
  border: 1px solid var(--border-color);
}

.btn.secondary:active {
  background-color: var(--dark-navy);
  color: var(--accent-red);
  border: 1px solid var(--accent-red);
}

.btn > span {
  font-size: 1rem;
}

/* DONT TOUCH */

.pattern-wrapper {
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.5rem 0rem 0.5rem;
  position: relative;
}

.input-menu {
  display: flex;
  align-items: center;
}

.input-menu svg {
  color: silver;
  margin-left: 1rem;
}

.input-menu svg:hover {
  color: #fff;
  cursor: pointer;
}

.input-menu svg:active {
  color: #89ddff;
  cursor: pointer;
}

.output-wrapper {
  padding: 0.5rem 0.5rem 0 0.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.output-wrapper > * {
  position: relative;
  overflow: hidden;
}

.collections-wrapper {
  padding: 1rem 0.5rem;
}

.collections-wrapper div a:hover {
  color: var(--light) !important;
}

.set-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0rem 0.5rem;
  padding: 0.5rem;
  background-color: #333;
  border: 1px solid #666;
}

.react-codemirror2 {
  max-width: 100%;
  height: 100%;
}

.CodeMirror {
  border: 1px solid var(--input-border);
}

.CodeMirror-focused {
  border: 1px solid var(--accent-red);
}

.btn {
  background-color: var(--button-bg);
  color: var(--button-text);
  box-shadow: 2px 2px rgba(0, 0, 0, 0.6);
}

button.btn :nth-child(1) {
  margin-right: 0.5rem;
}

.btn:hover {
  background-color: var(--button-hover);
  color: var(--light-text);
}

/* MULTI SELECT */

/* MEDIA QUERIES */

@media (max-width: 700px) {
  .navbar h1 {
    font-size: 1.5rem;
  }
  .navbar span.beta {
    display: none;
  }
  .nav-links span {
    display: none;
  }
  .nav-links svg {
    height: 1.2rem;
    width: 1.2rem;
  }
  .main {
    padding-bottom: 0;
  }
  .menu {
    display: none;
  }
  .container {
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "main" "result";
  }
}

