@import url("https://fonts.googleapis.com/css2?family=Work+Sans&display=swap");
@import "./codemirror/codemirror.css";
@import "../node_modules/codemirror/theme/material-darker.css";
@import "../node_modules/codemirror/addon/scroll/simplescrollbars.css";

:root {
  font-family: "Work Sans", sans-serif;
  --dark-navy: #0A1F44; /* Dark navy color assumed from the screenshot */
  --light-text: #FFFFFF; /* White text color for better contrast */
  --accent-red: #E83A30; /* Red accent color assumed from the screenshot */
  --modal-bg: #222222; /* Modal background color, a slightly lighter shade */
  --button-hover: #D32F2F; /* Button hover color, a darker shade of red */
  --border-color: #444444; /* Border color for elements like modals */
  --input-bg: #333333; /* Input field background color */
  --input-border: #666666; /* Input field border color */
  --notification-bg: #FFA07A; /* Notification background color */
  --button-bg: #89ddff; /* Default button background color */
  --button-text: #0A1F44; /* Button text color to match the dark navy theme */
}

a {
  text-decoration: none;
  color: var(--light-text);
}

* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  background-color: var(--dark-navy);
}

h1,
h2,
h3,
h4,
h5 {
  margin: 0.5rem;
  padding: 0rem;
  display: block;
  color: var(--light-text);
}

h3 {
  margin-right: 0;
}

td,
th {
  height: 1rem;
}

ul {
  padding: 0 0.5rem;
}

li {
  list-style-type: none;
}

button {
  border: none;
  background-color: var(--button-bg);
  color: var(--button-text);
  padding: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: var(--button-hover);
}

input:disabled {
  background-color: var(--input-bg);
  border-color: var(--input-border);
}

.nav-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-links a {
  color: var(--light-text);
  margin: 0 0.5rem;
  padding: 0.25rem;
  display: flex;
  align-items: center;
}

.nav-links a:hover {
  color: var(--accent-red);
}

.nav-links a span {
  margin: 0 0.25rem;
}

.container {
  display: grid;
  padding: 3rem 0 0 0;
  height: 100vh;
  grid-template-columns: 250px minmax(0, 4fr) minmax(0, 2fr);
  grid-template-areas: "menu main result";
}

.menu {
  grid-area: menu;
  background-color: var(--modal-bg);
  padding: 0.5rem;
  box-shadow: 2px -2px rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.result {
  grid-area: result;
  overflow: hidden;
  padding: 0.5rem 0.25rem 1rem 0.25rem;
}

.main {
  grid-area: main;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  padding: 0.5rem 0.25rem 1rem 0.25rem;
}

.samples-wrapper {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 100%;
  padding: 0.5rem 0.5rem 0 0.5rem;
  overflow: hidden;
}

.samples-wrapper > * {
  position: relative;
  overflow: hidden;
}

.output-top {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-right: 0.5rem;
}

.counter {
  justify-self: flex-end;
  font-size: 1.2rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.counter.all-match {
  color: #93eea8;
}

.counter.no-match {
  color: #f78c6c;
}

/* MODAL */

.modal {
  background-color: rgba(255, 255, 255, 0.3);
  position: fixed;
  z-index: 3;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding-top: 5vh;
  top: 0;
  left: 0;
}

.modal-container {
  background-color: var(--modal-bg);
  padding: 1rem;
  border: 1px solid var(--border-color);
  box-shadow: 3px 3px rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  width: 50vw;
}

.modal-header {
  margin-top: 0;
  margin-bottom: 1rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid var(--border-color);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.modal-header h3 {
  margin: 0 0 0 0.5rem;
  color: var(--light-text);
}

.modal-header svg {
  color: var(--light-text);
}

.modal-header svg:hover {
  color: var(--accent-red);
  cursor: pointer;
}

.grid-body {
  max-height: 75vh;
  overflow-y: auto;
}

.modal-content input {
  border-radius: 5px;
  font-size: 1rem;
  padding: 0.5rem;
  width: 100%;
  background-color: var(--input-bg);
  border: 1px solid var(--input-border);
  color: var(--light-text);
}

.modal-footer {
  margin-top: 1rem;
}

.notification {
  display: flex;
  align-items: center;
  background-color: var(--notification-bg);
  box-shadow: 2px 2px rgba(0, 0, 0, 0.6);
  width: 100%;
  padding: 0.5rem;
  border-radius: 5px;
  margin-bottom: 1rem;
  color: var(--dark-navy);
}

.notification span {
  margin-left: 0.5rem;
}

.error {
  background-color: #e57373; /* Adjusted for a consistent theme */
  color: var(--light-text);
}

.warn {
  background-color: #fff176; /* Adjusted for a consistent theme */
  color: var(--dark-navy);
}

.row div {
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  padding-top: 0.5rem;
  margin: 0 0.5rem;
}

.grid-headers div {
  display: flex;
  align-items: center;
}

.pattern-grid {
  background-color: #333;
}

.pattern-grid .grid-headers {
  font-weight: bold;
  color: #fff;
  background-color: #555;
  display: grid;
  grid-template-columns: 2fr 6fr 3rem 3rem;
  padding: 0.5rem;
  border: 1px solid #444;
  height: 3rem;
  width: 100%;
}

.pattern-grid .row {
  display: grid;
  padding: 0.5rem;
  grid-template-columns: 2fr 6fr 3rem 3rem;
  border: 1px solid #666;
  border-top: none;
}

.btn.delete {
  background-color: #f44336; /* Changed from salmon to a consistent red */
}

.btn.edit {
  background-color: var(--accent-red); /* Use the accent color for edit buttons */
}

.btn.success {
  background-color: #4caf50; /* A success green color */
}

.btn.secondary {
  background-color: transparent;
  color: var(--light-text);
  border: 1px solid var(--border-color);
}

.btn.secondary:active {
  background-color: var(--dark-navy);
  color: var(--accent-red);
  border: 1px solid var(--accent-red);
}

.btn > span {
  font-size: 1rem;
}

/* DONT TOUCH */

.pattern-wrapper {
  height: auto;
  display: flex;
  flex-direction: column;
  padding: 0.5rem 0.5rem 0rem 0.5rem;
  position: relative;
}

.input-menu {
  display: flex;
  align-items: center;
}

.input-menu svg {
  color: silver;
  margin-left: 1rem;
}

.input-menu svg:hover {
  color: #fff;
  cursor: pointer;
}

.input-menu svg:active {
  color: #89ddff;
  cursor: pointer;
}

.output-wrapper {
  padding: 0.5rem 0.5rem 0 0.5rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
}

.output-wrapper > * {
  position: relative;
  overflow: hidden;
}

.collections-wrapper {
  padding: 1rem 0.5rem;
}

.collections-wrapper div a:hover {
  color: var(--light) !important;
}

.set-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0rem 0.5rem;
  padding: 0.5rem;
  background-color: #333;
  border: 1px solid #666;
}

.react-codemirror2 {
  max-width: 100%;
  height: 100%;
}

.CodeMirror {
  border: 1px solid var(--input-border);
}

.CodeMirror-focused {
  border: 1px solid var(--accent-red);
}

.btn {
  background-color: var(--button-bg);
  color: var(--button-text);
  box-shadow: 2px 2px rgba(0, 0, 0, 0.6);
}

button.btn :nth-child(1) {
  margin-right: 0.5rem;
}

.btn:hover {
  background-color: var(--button-hover);
  color: var(--light-text);
}

/* MULTI SELECT */

/* MEDIA QUERIES */

@media (max-width: 700px) {
  .navbar h1 {
    font-size: 1.5rem;
  }
  .navbar span.beta {
    display: none;
  }
  .nav-links span {
    display: none;
  }
  .nav-links svg {
    height: 1.2rem;
    width: 1.2rem;
  }
  .main {
    padding-bottom: 0;
  }
  .menu {
    display: none;
  }
  .container {
    grid-template-columns: auto;
    grid-template-rows: 1fr 1fr;
    grid-template-areas: "main" "result";
  }
}
